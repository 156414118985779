
import { defineComponent, ref, unref, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';

import { useCreateModel, useUpdateModel, useModel } from '@/composables/api';
import { PartialModel, ModelGender } from '@/interfaces/Model'; // Model Social Media, add later
import useFormErrors from '@/utils/validate';
import { IntBoolean } from '@/services/api';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialModel = {
  name: '',
  nameCn: '',
  avatar: '',
  gender: ModelGender.Male
};

const rules: Rules = {
  avatar: [
    {
      required: true
    }
  ],
  name: [
    {
      required: true
    }
  ],
  gender: [
    {
      required: true
    }
  ]
};

const genderOptions = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  }
];

// const socialMediaOptions = [
//   {
//     label: 'Facebook',
//     value: 'facebook'
//   },
//   {
//     label: 'Instagram',
//     value: 'instagram'
//   },
//   {
//     label: 'Twitter',
//     value: 'twitter'
//   },
//   {
//     label: 'TickTok',
//     value: 'ticktock'
//   },
//   {
//     label: 'Discord', // option not in backend yet, need to add
//     value: 'discord'
//   },
//   {
//     label: 'Redit', // option not in backend yet, need to add
//     value: 'redit'
//   },
// ];

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const modelId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();
    const { t } = useI18n();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { isLoading: isCreatedLoading, mutate: create } = useCreateModel();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateModel();
    const { formErrors, bindFormItemError } = useFormErrors();
    const { data, dataUpdatedAt } = useModel({ modelId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });
    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    const handleAvatarCrop = ({ path }: UploadImageEvent) => {
      formValues.value.avatar = path;
    };

    // const handleCoverCrop = ({ path }: UploadImageEvent) => {
    //   formValues.value.cover = path;
    // };

    // const handleMobileCoverCrop = ({ path }: UploadImageEvent) => {
    //   formValues.value.mobile_cover = path;
    // };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { modelId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.push({
                      name: 'list-models'
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                    formErrors.value = error.response?.data.errors;
                  }
                }
              );

              return;
            }

            create(
              { data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  formValues.value = DEFAULT_FORM_VALUES;
                  router.push({
                    name: 'list-models'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                  formErrors.value = error.response?.data.errors;
                }
              }
            );
          }
        });
    };

    return {
      page,
      formValues,
      formRef,
      rules,
      IntBoolean,
      handleAvatarCrop,
      // handleCoverCrop,
      // handleMobileCoverCrop,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      genderOptions,
      // socialMediaOptions,
      formErrors,
      t,
      bindFormItemError
    };
  }
});
